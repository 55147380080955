<template>
	<div class="flex" style="flex-direction: column;height: 100%;">
		<sm-card title="筛选查询" noHeader icon="el-icon-search" style="height:80px;">
			<el-form slot="body" class="flex" inline ref="form" :model="searchParm" label-width="120px">
				<el-row class="flex-1">
					<el-col :span="8">
						<el-form-item label="用户账号">
							<el-input v-model="searchParm.accountNo" placeholder="用户ID/账号/手机/姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="14">
						<el-row>
							<el-form-item label="注册时间">
								<el-col :span="10">
									<el-date-picker v-model="searchParm.timeStart" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
								<el-col :span="2" style="text-align: center;">
									<span>-</span>
								</el-col>
								<el-col :span="10">
									<el-date-picker v-model="searchParm.timeEnd" value-format="yyyy-MM-dd HH:mm:ss"
										type="datetime" placeholder="请选择日期">
									</el-date-picker>
								</el-col>
							</el-form-item>
						</el-row>
					</el-col>
				</el-row>
				<div>
					<el-button type="primary" @click="list()">查询</el-button>
					<el-button plain @click="clearSearch()">重置</el-button>
				</div>
			</el-form>
		</sm-card>
		<div class="tableModule">
			<div class="title">
				<div class="title-left">
					<i class="el-icon-s-operation"></i>
					<span>数据列表</span>
				</div>
				<div class="title-right">
					<el-select v-model="searchParm.size" size="medium" :popper-append-to-body="false" placeholder="显示条数"
						@change="list()">
						<el-option label="5" :value="5"></el-option>
						<el-option label="10" :value="10"></el-option>
						<el-option label="20" :value="20"></el-option>
						<el-option label="50" :value="50"></el-option>
						<el-option label="100" :value="100"></el-option>
					</el-select>
				</div>
			</div>
			<div class="tableContainer">
				<div class="table" ref="tableContainer">
					<div>
						<el-table ref="usertable" :data="tableData.records" border :height="tableHeight"
							:header-cell-style="{'background-color':'#f9fafc'}" :cell-style="{'padding':0}"
							style="margin-bottom: 20px;">
							<el-table-column prop="userName" align="center" label="用户名" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="mobile" align="center" label="手机号" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="time" align="center" label="时间" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="ip" align="center" label="IP" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="location" align="center" label="地区" show-overflow-tooltip>
							</el-table-column>
							<el-table-column prop="terminalOs" align="center" label="登录方式" show-overflow-tooltip>
								<template slot-scope="scope">
									<div v-if="scope.row.terminalOs==1">pc</div>
									<div v-if="scope.row.terminalOs==2">手机</div>
								</template>
							</el-table-column>
							<el-table-column prop="terminalInfo" align="center" label="终端信息" show-overflow-tooltip>
							</el-table-column>
						</el-table>
					</div>
				</div>
				<div class="pagination">
					<el-pagination style="float: right;" @current-change="handleCurrentChange"
						:current-page.sync="searchParm.current" :page-size="searchParm.size"
						layout="total, prev, pager, next,jumper" :total="tableData.total">
					</el-pagination>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
	import smCard from "@c/smCard.vue"
	export default {
		components: {
			smCard,
		},
		data() {
			return {
				tableHeight: 0,
				tableData: {
					records: [],
				},
				searchParm: {
					size: 20,
					current: 1
				},
			}
		},
		methods: {
			//自适应表格高度
			calHeight() {
				this.$nextTick(() => {
					const rect = this.$refs.tableContainer.getBoundingClientRect()
					this.tableHeight = rect.height
				})
			},
			//清空搜索
			clearSearch() {
				this.searchParm = {
					size: 20,
					current: 1
				}
			},
			//展示数据
			list() {
				this.$get("/user-api/user_login/plat/list", this.searchParm).then((res) => {
					if (res.code == 1000) {
						this.tableData = res.data;
					} else {
						this.$message.error(res.msg);
					}
				});
			},
			//分页选择页面回调
			handleCurrentChange(val) {
				this.searchParm.current = val;
				this.list();
			},
		},
		mounted() {
			this.list();
			this.calHeight();
			window.addEventListener('resize', this.calHeight)
		},
		beforeDestroy() {
			window.removeEventListener('resize', this.calHeight)
		},
	};
</script>
<style lang='scss' scoped>
	@import "@style/userCenter.scss";

	.el-table {
		::v-deep .el-table__row {
			height: 41px;
		}
	}
</style>
